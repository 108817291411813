/* Overall Header Styling */
header {
  
    width: 100%;
    background-color: #e8d2de;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  /* First Row Styling */
  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
  }
  
  .header-logo img {
    height: 40px;
  }
  
  .header-search {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0 20px;
  }
  
  .header-search input {
    width: 90%;
    border: 1px solid #550a35;
    padding: 15px 10px;
    border-radius: 4px 0 0 4px;
    outline: none;
    font-size: 14px;
   background-color:  white;
  }
  
  .header-search button {
    background-color: #550a35;
    color: white;
    border: none;
    padding: 15px 15px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 14px;
  }
  
  .header-icons {
    display: flex;
    gap: 25px;
    padding: 15px 15px;
  }
  
  .header-icons a {
    color: #550a35;
    font-size: 20px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .header-icons a:hover {
    color: #800053;
  }
  
  /* Second Row Styling */
  .header-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #550a35;
    gap: 25px;
  }
  
  .header-navbar a {
    margin: 0 15px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  /* .header-navbar a:hover {
    color: #550a35;
  } */
  
  .navbar-dropdown {
    position: relative;
  }
  
  .dropdown-icon {
    margin-left: 5px;
    font-size: 12px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    border-radius: 4px;
    min-width: 120px;
    z-index: 10;
  }
  
  .dropdown-menu a {
    padding: 8px 16px;
    text-decoration: none;
    color: black;
    font-size: 11px;
    font-weight: normal;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-menu a:hover {
    background-color: #f0f0f0;
  }
  
 
@media (max-width: 600px) {
  .header-top {
    padding: 10px; /* Reduce padding for mobile */
  }

  .header-logo img {
    height: 30px; /* Adjust logo size */
  }

  .header-icons {
    gap: 15px; /* Reduce the gap between icons */
  }

  .header-navbar {
    display: none; /* Hide navbar in mobile view */
  }


  .header-search input {
    flex-grow: 1; /* Allow the input field to grow */
    padding: 5px; /* Add padding to the input field */
    border: 1px solid #ccc; /* Add a border for better visibility */
    border-radius: 4px 0 0 4px; /* Rounded corners for input field */
    margin-bottom: 15px;
  }

  .header-search button {
    padding: 5px 10px; /* Adjust padding for the button */ 
    color: white; /* Make text color white */
    border: none; /* Remove border */
    border-radius: 0 4px 4px 0; /* Rounded corners for button */
    cursor: pointer; /* Add a pointer cursor */
    margin-bottom: 15px;
  }
}