/* HomeAutomation.css */
.overlay {
    background: rgba(0, 0, 0, 0.6); /* Dark overlay for contrast */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main-heading {
    position: relative;
    color: #ffffff;
    font-size: 4rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    padding: 20px;
    font-weight: 300;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    animation: fadeIn 2s ease-in-out; /* Animation added */
  }
    
  .highlight {
    color: #00c0ff; /* Light blue color as seen in the image */
    font-size: 5rem;
    font-weight: bold;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  

  
  .text-sectionapptop {
    padding: 2rem;
    text-align: center;
    font-family: 'Bold and geometric, great for headers';
    color: #333;
    font-size: 3.5rem;
  }
  .text-sectionappbottom {
    padding: 4rem;
    /* text-align: center; */
    font-family: 'Crisp and highly readable';
    color: #333;
    font-size: 1.5rem;
    margin-left: 200px;
    margin-right: 200px;
   word-spacing: 0.8rem;
   letter-spacing: 0.1rem;
   /* margin-top: 3rem; */
  }

  
  .image-sectionapp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .image-sectionapp1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .white-text {
    color: black;

  }


  
  .landscape-imageapp {
    width: 80%;
    height: auto;
    object-fit: contain;
  }



  .text-section {
    padding: 2rem;
   
    font-family: 'Crisp and highly readable';
    color: #333;
    font-size: 1.5rem;
    margin-left: 200px;
    margin-right: 200px;
   word-spacing: 0.8rem;
   letter-spacing: 0.1rem;
  }
  
  .image-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  
  .landscape-image {
    width: 80%;
    height: auto;
    object-fit: contain;
  }

.contentheading{
  /* padding: 2rem; */
  /* text-align: center; */
  font-family:Clean, modern, and versatile;
  color: red;
  font-size: 2rem;
}



.shop-the-lookhome {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px; /* Adjust the space between the images */
  padding: 20px;
}

.image-containerhome {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-containerhome img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the aspect ratio */
}


.automationfooterhome {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 60%;
  margin: 20px auto;
}

.section-headinghome {
  color: #550a35;
  font-size: 24px;
  margin-bottom: 20px;
}


.button-containerhome {
  display: flex;
  justify-content: center;
  gap: 100px;
  padding: 2rem;
}

.sign-in-buttonhome1 {
  background-color: #550a35;
  color: white;
  font-size: 20px;
  padding: 12px 40px;
  border: 1px solid #550a35;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sign-in-buttonhome1:hover {
  background-color: #e8d2de;
  color: #550a35;
 
  transform: scale(1.05); /* Slight enlargement */
}



.sign-in-buttonhome2 {
  background-color: white;
  color: #550a35;
  font-size: 20px;
  padding: 12px 40px;
  border: 1px solid #550a35;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  
}

.sign-in-buttonhome2:hover {
  background-color: #e8d2de;
  transform: scale(1.05); /* Slight enlargement */
}