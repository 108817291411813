.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: auto;
    max-width: 900px;
   
  }
  
  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .about-us-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 8px rgba(10, 10, 10, 10);
  }
  
  .quote-container {
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    /* border:2px solid black ; */
     
   
  }
  
  .quote-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    /* box-shadow: 0 4px 8px rgba(10, 10, 10, 10); */
  }
  
  .quote-text {
    font-size: 1.5rem;
    font-style:POPPINS;
    color: black;
    margin-bottom: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    
  }
  
  .about-us-description {
    font-size: 1rem;
    color: #1f1e1e;
    margin-top: 20px;
    line-height: 1.6;
    /* box-shadow: 0 4px 8px rgba(10, 10, 10, 10); */
  }
  
  @media (min-width: 768px) {
    .about-us-container {
      flex-direction: row;
      /* flex-direction: column; */
    }
    
    .image-container {
      flex: 1;
      margin-right: 20px;
    }
    
    .quote-container {
      flex: 2;
      text-align: left;
      margin-top: 0;
    }
  }
  