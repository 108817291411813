/* Main Form Container */
.form-container {
    max-width: 800px;
    margin: 40px auto;
    background: linear-gradient(135deg, #ffffff, #f3f3f3);
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    padding: 30px 40px;
    font-family: 'Arial', sans-serif;
    animation: fadeIn 1s ease-in-out;
    overflow: hidden;
  }
  
  /* Form Header */
  .form-header {
    text-align: center;
    margin-bottom: 30px;
    animation: slideDown 1s ease-out;
  }
  
  /* Form Title */
  .form-title {
    font-size: 32px;
    color: #FF6A00;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
    animation: popIn 1.2s ease-in-out;
  }
  
  .form-title .highlight {
    color: #122151;
    font-weight: bold;
    font-size: 3rem;
  }
  
  .form-subtitle {
    font-size: 16px;
    color: black;
    font-weight: bold;
  }
  
  /* Animated Form Sections */
  .form-section {
    margin-bottom: 35px;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(120deg, #ffffff, #f9f9f9);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  .form-section h2 {
    font-size: 24px;
    color: #550a35;
    margin-bottom: 15px;
    border-left: 5px solid #550a35;
    padding-left: 10px;
    font-weight: bold;
    animation: slideRight 1s ease-in-out;
  }
  
  /* Form Groups */
  .form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 25px;
  }
  
  .form-group label {
    flex: 1 1 100%;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .form-group input,
  .form-group select {
    flex: 1 1 calc(50% - 20px);
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s, box-shadow 0.3s, transform 0.2s;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: #550a35;
    box-shadow: 0 0 10px rgba(85, 10, 53, 0.5);
    transform: scale(1.02);
  }
  
  .form-group input[type="radio"] {
    flex: 0 0 auto;
    width: auto;
    margin-right: 8px;
  }
  
  /* Error Messages */
  .error {
    font-size: 13px;
    color: #d9534f;
    margin-top: 5px;
  }
  
  /* Submit Button with Advanced Animation */
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    background-color: #550a35;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #6f0c44;
    transform: scale(1.05) rotate(-1deg);
    box-shadow: 0 6px 15px rgba(85, 10, 53, 0.5);
  }
  
  button[type="submit"]:active {
    transform: scale(0.98) rotate(1deg);
    background-color: #500934;
  }
  
  /* Success Message */
  .success-message {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    color: #4caf50;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .form-group input,
    .form-group select {
      flex: 1 1 100%;
    }
    .form-section {
      padding: 15px;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes popIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }