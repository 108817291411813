.order-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.success-container {
  text-align: center;
  background-color: #ffffff;
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.checkmark-container {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-color: #4caf50;
  border-radius: 50%;
  position: relative;
  margin-bottom: 20px;
  animation: scaleIn 1s ease-in-out;
}

.checkmark {
  width: 40px;
  height: 20px;
  border: 5px solid white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  animation: checkmarkDraw 1.5s ease-in-out forwards;
}

h2 {
  font-size: 28px;
  color: #4caf50;
  font-weight: bold;
  margin-bottom: 10px;
  animation: fadeInUp 1s ease-in-out;
}

p {
  font-size: 16px;
  color: #777;
  font-weight: 500;
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes checkmarkDraw {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 40px;
    opacity: 1;
  }
}