/* General Container */
.order-summary-container {
  background-color: #f3f5f7;
  padding: 25px;
  border-radius: 12px;
  max-width: 95%;
  margin: 20px auto;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  font-family: 'Segoe UI', Tahoma, Geneva, sans-serif;
  color: #333;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Error Message */
.order-summary-error {
  color: #e74c3c;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

/* Card for Each Order */
.order-card {
  background-color: #fff;
  border-radius: 10px;
  width: 100%; /* Ensure it takes full width of the container */
  max-width: 800px; /* Limit the max width */
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Order Content */
.order-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.order-product-image {
  width: 180px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.08);
}

/* Order Details */
.order-details {
  display: flex;
  flex-direction: column;
  gap: 10px;


}

.order-detail {
  font-size: 1.05rem;
  color: #555;
  font-weight: 500;
  line-height: 1.4;
}

.order-detail span {
  font-weight: 700;
  color: #333;
  margin-right: 10px;
}

/* Buttons Row */
.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Edit and Continue Shopping Buttons */
.cancel-button,
.continue-shopping-button {
  padding: 12px 24px;
  border: 550a35;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  color: white;
}

.cancel-button {
  background-color: #550a35;
}

.cancel-button:hover {
  transform: translateY(-2px);
}

.continue-shopping-button {
  background-color: #550a35;
  color: white;
}

.continue-shopping-button:hover {
  background-color: #550a35;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .order-summary-title {
    font-size: 1.8rem;
  }

  .order-product-image {
    max-width: 150px;
  }

  .order-content {
    flex-direction: column;
    align-items: center;
  }

  .button-row {
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .cancel-button,
  .continue-shopping-button {
    width: 100%;
    padding: 12px 0;
  }

  /* Adjustments to button text size for better mobile fit */
  .cancel-button,
  .continue-shopping-button {
    font-size: 1.1rem;
    padding: 14px 0; /* Slightly larger padding for easier clicking */
  }

  /* Fixing position for Continue Shopping button when it's fixed */
  .continue-shopping-button.common {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    padding: 12px 0;
  }
}

/* Confirmation Dialog */
.confirmation-dialog {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 90%;
  max-width: 400px;
  animation: fadeIn 0.3s ease;
}

.confirmation-dialog p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1.4;
}

/* Dialog Buttons */
.confirm-cancel-button,
.dont-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;
}

.confirm-cancel-button {
  background-color: #dc3545;
  color: white;
  margin-right: 10px;
}

.confirm-cancel-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.dont-cancel-button {
  background-color: #6c757d;
  color: white;
}

.dont-cancel-button:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

/* Responsive Design for Confirmation Dialog */
@media (max-width: 768px) {
  .confirmation-dialog {
    width: 90%;
    max-width: 100%;
    padding: 15px;
  }

  .confirmation-dialog p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .confirm-cancel-button,
  .dont-cancel-button {
    width: 100%;
    margin: 5px 0;
    padding: 12px 0;
  }
}

/* Animation */
@keyframes fadeIn {
  0% { opacity: 0; transform: translate(-50%, -40%); }
  100% { opacity: 1; transform: translate(-50%, -50%); }
}

/* Reason Dropdown - Enhanced */
.reason-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.reason-dropdown {
  width: 100%;
  max-width: 400px;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 15px 0;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  appearance: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.reason-dropdown:focus {
  border-color: #550a35;
  box-shadow: 0 0 5px rgba(85, 10, 53, 0.4);
  outline: none;
}

/* Add icon for dropdown */
.reason-dropdown::after {
  content: '\25BC';
  position: absolute;
  right: 15px;
  font-size: 0.8rem;
  color: #999;
}

/* Mobile View Order Details Alignment */
@media (max-width: 768px) {
  .order-summary-title {
    font-size: 1.8rem;
  }

  .order-product-image {
    max-width: 150px;
  }

  .order-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .order-details {
    align-items: flex-start;
    gap: 12px;
    text-align: left;
  }

  .order-detail {
    font-size: 1rem;
    color: #555;
    font-weight: 500;
    line-height: 1.5;
    margin: 5px 0;
  }

  .order-detail span {
    font-weight: 700;
    color: #333;
    margin-right: 10px;
  }

  .button-row {
    flex-direction: column;
    gap: 12px;
  }

  .cancel-button,
  .continue-shopping-button {
    width: 100%;
    padding: 12px 0;
  }
}

.order-summary-container {
  position: relative;
}

.continue-shopping-button.common {
  position: relative;
  top: auto;
  right: auto;
  background-color: #550a35;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


/* Title styling */
/* .order-summary-title {
  font-size: 2.2rem;
  text-align: center;
  font-weight: bold;
  color: #550a35;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.order-summary-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #550a35;
  border-radius: 2px;
} */

/* Button styles */
.continue-shopping-button,
.cancel-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background-color: #550a35; /* Your preferred color */
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.continue-shopping-button:hover,
.cancel-button:hover {
  background-color: #720d44; /* Slightly darker shade for hover */
  transform: translateY(-2px);
}

.continue-shopping-button:active,
.cancel-button:active {
  transform: translateY(1px);
}

.cancel-button.disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Order section titles */
h2 {
  font-size: 1.7rem;
  margin-top: 30px;
  border-bottom: 3px solid #550a35;
  padding-bottom: 8px;
  color: #550a35;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Grid structure for each section */
.order-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

/* Order card styles */
/* .order-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
} */

.order-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.order-card.cancelled {
  border-color: #d9534f;
  background-color: #fbe3e3;
}

.order-card .order-content {
  display: flex;
  gap: 20px;
}

/* .order-product-image {
  width: 90px;
  height: 90px;
  border-radius: 6px;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-detail {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
} */

.blurred {
  opacity: 0.6;
  filter: blur(2px);
}

/* Button row styling */
.button-row {
  margin-top: 20px;
  text-align: right;
  align-items: right;
}

/* Error message styling */
/* .order-summary-error {
  color: #d9534f;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 20px;
} */

/* Modal for cancellation prompt */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: scale(0.95);
  animation: modalIn 0.3s forwards;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: #550a35;
}

.modal-content select {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.modal-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.modal-buttons button {
  padding: 10px 18px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-buttons button:nth-child(1) {
  background-color: #550a35;
  color: #fff;
}

.modal-buttons button:nth-child(2) {
  background-color: #ccc;
  color: #333;
}

.modal-buttons button:hover {
  transform: translateY(-2px);
}

.modal-buttons button:active {
  transform: translateY(1px);
}

.error {
  color: #d9534f;
  font-size: 1rem;
  margin-top: 15px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .order-summary-title {
    font-size: 1.9rem;
  }

  .order-section {
    grid-template-columns: 1fr;
  }

  .order-card .order-content {
    flex-direction: column;
    align-items: center;
  }

  .modal-content {
    width: 85%;
  }
}

/* Modal Animation */
@keyframes modalIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}